import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiFeedbackGetGivenFeedbacksByUserId: build.query<
      GetApiFeedbackGetGivenFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetGivenFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetGivenFeedbacks/${queryArg.userId}`,
      }),
    }),
    getApiFeedbackGetReceivedFeedbacksByUserId: build.query<
      GetApiFeedbackGetReceivedFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetReceivedFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetReceivedFeedbacks/${queryArg.userId}`,
      }),
    }),
    getApiFeedbackGetTeamFeedbacksByUserId: build.query<
      GetApiFeedbackGetTeamFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetTeamFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetTeamFeedbacks/${queryArg.userId}`,
      }),
    }),
    postApiFeedbackAddFeedback: build.mutation<
      PostApiFeedbackAddFeedbackApiResponse,
      PostApiFeedbackAddFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/AddFeedback`,
        method: "POST",
        body: queryArg.saveFeedbackDto,
      }),
    }),
    postApiFeedbackRequestFeedback: build.mutation<
      PostApiFeedbackRequestFeedbackApiResponse,
      PostApiFeedbackRequestFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/RequestFeedback`,
        method: "POST",
        body: queryArg.saveFeedbackDto,
      }),
    }),
    getApiFeedbackGetFeedbackRequestsByUserId: build.query<
      GetApiFeedbackGetFeedbackRequestsByUserIdApiResponse,
      GetApiFeedbackGetFeedbackRequestsByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetFeedbackRequests/${queryArg.userId}`,
      }),
    }),
    getApiFeedbackGetRequestedFeedbacksByUserId: build.query<
      GetApiFeedbackGetRequestedFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetRequestedFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetRequestedFeedbacks/${queryArg.userId}`,
      }),
    }),
    postApiFeedbackRespondToFeedbackRequest: build.mutation<
      PostApiFeedbackRespondToFeedbackRequestApiResponse,
      PostApiFeedbackRespondToFeedbackRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/RespondToFeedbackRequest`,
        method: "POST",
        body: queryArg.respondFeedbackRequestDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiFeedbackGetGivenFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetGivenFeedbacksByUserIdApiArg = {
  userId: string;
};
export type GetApiFeedbackGetReceivedFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetReceivedFeedbacksByUserIdApiArg = {
  userId: string;
};
export type GetApiFeedbackGetTeamFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetTeamFeedbacksByUserIdApiArg = {
  userId: string;
};
export type PostApiFeedbackAddFeedbackApiResponse = unknown;
export type PostApiFeedbackAddFeedbackApiArg = {
  saveFeedbackDto: SaveFeedbackDto;
};
export type PostApiFeedbackRequestFeedbackApiResponse = unknown;
export type PostApiFeedbackRequestFeedbackApiArg = {
  saveFeedbackDto: SaveFeedbackDto;
};
export type GetApiFeedbackGetFeedbackRequestsByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetFeedbackRequestsByUserIdApiArg = {
  userId: string;
};
export type GetApiFeedbackGetRequestedFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetRequestedFeedbacksByUserIdApiArg = {
  userId: string;
};
export type PostApiFeedbackRespondToFeedbackRequestApiResponse = unknown;
export type PostApiFeedbackRespondToFeedbackRequestApiArg = {
  respondFeedbackRequestDto: RespondFeedbackRequestDto;
};
export type FeedbackDto = {
  id?: string;
  fromUserId?: string;
  fromUserName?: string | null;
  toUserId?: string;
  toUserName?: string | null;
  feedbackReasonId?: string;
  feedbackReason?: string | null;
  responseDetail?: string | null;
  responseDate?: string | null;
  requestDetail?: string | null;
  detail?: string | null;
  feedbackDate?: string;
  status?: string | null;
  type?: string | null;
};
export type SaveFeedbackDto = {
  toUserId?: string;
  feedbackReasonId?: string;
  detail?: string | null;
};
export type FeedbackStatus = 0 | 1 | 2;
export type RespondFeedbackRequestDto = {
  requestId?: string;
  status?: FeedbackStatus;
  detail?: string | null;
};
export const {
  useGetApiFeedbackGetGivenFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetGivenFeedbacksByUserIdQuery,
  useGetApiFeedbackGetReceivedFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetReceivedFeedbacksByUserIdQuery,
  useGetApiFeedbackGetTeamFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetTeamFeedbacksByUserIdQuery,
  usePostApiFeedbackAddFeedbackMutation,
  usePostApiFeedbackRequestFeedbackMutation,
  useGetApiFeedbackGetFeedbackRequestsByUserIdQuery,
  useLazyGetApiFeedbackGetFeedbackRequestsByUserIdQuery,
  useGetApiFeedbackGetRequestedFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetRequestedFeedbacksByUserIdQuery,
  usePostApiFeedbackRespondToFeedbackRequestMutation,
} = injectedRtkApi;
