import React, {useEffect, useState} from 'react';
import {Badge, Button, Select, Spin, Table} from "antd";
import OneOnOneDetailsPopup from "../../../components/OneOnOnes/OneOnOneDetailsPopup";
import {PeriodDto, useLazyGetApiPeriodGetActivePeriodsQuery} from "../../../api/services/period";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../../api/services/user";
import {
    useLazyGetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdQuery
} from "../../../api/services/OneToOneMeeting";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import moment from "moment/moment";
import {IDetailItem} from "../data";

export interface IReportDetailOneToOneMeeting {
    selectedItem: IDetailItem | undefined
}

const ReportDetailOneToOneMeeting: React.FC<IReportDetailOneToOneMeeting> = ({selectedItem}) => {
    const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
    const [fetchMeetings, {data: meetings}] = useLazyGetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdQuery()
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodGetActivePeriodsQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [isDetailsPopupVisible, setIsDetailsPopupVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (user) {
            getMeetings()
        }
    }, [selectedItem])
    
    const getMeetings = async () => {
        try {
            setLoading(true)
            await fetchMeetings({
                teamMemberId: selectedItem?.id ?? "",
                periodId: "c2cb3c84-0d10-4d0e-9b3f-52b23a697c17"
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Görüşmeler çekilirken hata oluştu')));
        } finally {
            setLoading(false)
        }
    }

    const handleRowClick = (record: any) => {
        setSelectedRecord(record);
        setIsDetailsPopupVisible(true);
    };

    const columns = [
        {title: 'Görüşme Adı', dataIndex: 'meetingName', key: 'meetingName'},
        {title: 'Tip', dataIndex: 'meetingTypeName', key: 'meetingTypeName'},
        {
            title: 'Tarih', dataIndex: 'meetingDate', key: 'meetingDate',
            render: (_: any, record: any) => moment(record.meetingDate).format('DD.MM.YYYY'),
        },
        {
            title: 'Durum', dataIndex: 'status', key: 'status',
            render: (_: any, record: any) => {
                if (record.status == 'Waiting' && !record.employeeComment) {
                    return <Badge status="error" text={t('Çalışan yorumu bekleniyor')}/>
                } else if (record.status == 'Waiting' && !record.managerComment) {
                    return <Badge status="error" text={t('Yönetici yorumu bekleniyor')}/>
                } else if (record.status == 'Completed') {
                    return <Badge status="success" text={t('Tamamlandı')}/>
                }
            }
        },
    ];

    return (<>
        <div>
            <Spin spinning={loading}>
                <Table dataSource={meetings}
                       columns={columns}
                       rowKey="id"
                       pagination={false}
                       onRow={(record) => ({
                           onClick: () => handleRowClick(record),
                       })}
                />
            </Spin>
            <OneOnOneDetailsPopup
                visible={isDetailsPopupVisible}
                onClose={() => setIsDetailsPopupVisible(false)}
                data={selectedRecord}
                toManager={false}
                isReadOnly={true}
                onSave={(updatedData) => {
                    getMeetings()
                }}
            />
        </div>
    </>)
};

export default ReportDetailOneToOneMeeting;