import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Select, Button, Badge} from 'antd';
import {
    FeedbackDto,
    RespondFeedbackRequestDto,
    usePostApiFeedbackAddFeedbackMutation,
    usePostApiFeedbackRespondToFeedbackRequestMutation
} from "../../api/services/Feedback";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {useLazyGetApiUserUsersForComboByCompanGroupIdQuery, UserDto} from "../../api/services/user";
import {ParameterType, usePostApiParameterGetParametersMutation} from "../../api/services/parameters";
import {extractParam} from "../../helper/paramHelper";
import {DataItem} from "../../models/DataItem";

const {TextArea} = Input;
const {Option} = Select;

interface CreateFeedbackPopupProps {
    visible: boolean;
    onClose: () => void;
    onUpdate: () => void;
    request: FeedbackDto | undefined
}

const CreateFeedbackPopup: React.FC<CreateFeedbackPopupProps> = ({
                                                                     request,
                                                                     visible,
                                                                     onClose,
                                                                     onUpdate
                                                                 }) => {
    const [createFeedback] = usePostApiFeedbackAddFeedbackMutation()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [getUsers, {data: usersForCombo}] = useLazyGetApiUserUsersForComboByCompanGroupIdQuery()
    const [getParameters, {data: parameters}] = usePostApiParameterGetParametersMutation()
    const [FeedbackReasons, setFeedbackReasons] = useState<DataItem[]>([])

    useEffect(() => {
        if (user) {
            FetchUsers()
            FetcParams()
        }
    }, [user])

    const FetcParams = async () => {
        try {
            dispatch(setLoading(true));
            var parameters = await getParameters({
                companyGroupId: user?.company?.companyGroupId ?? "",
                body: [
                    20 as ParameterType, //Feedback Reasons
                ]
            }).unwrap()
            setFeedbackReasons(extractParam(parameters, 20))
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getUsers({
                companGroupId: user?.company?.companyGroupId ?? "",
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const CreateFeedback = async (values: any) => {
        try {
            dispatch(setLoading(true));
            await createFeedback({
                saveFeedbackDto: {
                    toUserId: values.person ?? "",
                    feedbackReasonId: values.reason ?? "",
                    detail: values.detail ?? ""
                }
            }).unwrap()

            onUpdate();
            onClose();
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Geribildirim verilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleFinish = (values: any) => {
        CreateFeedback(values);
    };

    return (
        <Modal visible={visible} title="Geribildirim Talebi Cevapla" footer={null} onCancel={onClose}>
            <Form layout="vertical"
                  onFinish={handleFinish}
            >

                <Form.Item name="person" label="Kişi" rules={[{required: true, message: 'Bir kişi seçiniz!'}]}>
                    <Select placeholder="Kişi seçiniz"
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((usersForCombo ?? []) as UserDto[]).filter(k => k.id !== user?.id).map(k => {
                                return {value: k.id, label: `${k.firstName} ${k.lastName} ${k.title ? "(" + k.title + ")" : ""}`};
                                }
                            )}/>
                </Form.Item>
                <Form.Item name="reason" label="Geri Bildirim Nedeni"
                           rules={[{required: true, message: 'Bir neden seçiniz!'}]}>
                    <Select placeholder="Neden seçiniz"
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((FeedbackReasons ?? []) as DataItem[]).map(k => {
                                return {value: k.id, label: `${k.name}`};
                            })}
                    />
                </Form.Item>
                <Form.Item name="detail" label="Açıklama" rules={[{required: true, message: 'Açıklama giriniz!'}]}>
                    <TextArea rows={4}/>
                </Form.Item>
                <Button type="primary" htmlType="submit" style={{marginTop: 16}}>
                    Geribildirim Gönder
                </Button>
            </Form>
        </Modal>
    )
};

export default CreateFeedbackPopup;
