import React, {useEffect, useState} from 'react';
import { Table, Select, Button } from 'antd';
import FeedbackPopup from '../../components/Feedback/FeedbackPopup';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {ParameterType} from "../../api/services/parameters";
import {extractParam} from "../../helper/paramHelper";
import {t} from "i18next";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../api/services/user";
import {
    useLazyGetApiFeedbackGetFeedbackRequestsByUserIdQuery,
    useLazyGetApiFeedbackGetTeamFeedbacksByUserIdQuery
} from "../../api/services/Feedback";
import moment from "moment/moment";

const { Option } = Select;

interface Feedback {
    id: number;
    giver: string;
    reason: string;
    detail: string;
    date: string;
}

const TeamFeedbacks: React.FC = () => {
    const [selectedMember, setSelectedMember] = useState<string | null>(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [getTeamUsers, {data: teamUsers}] = useLazyGetApiUserTeamMembersQuery()
    const [fetchTeamFeedback, {data: teamFeedback}] = useLazyGetApiFeedbackGetTeamFeedbacksByUserIdQuery()
    
    useEffect(() => {
        if (user) {
            FetchTeamUsers()
        }
    }, [user])


    const FetchTeamUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getTeamUsers().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getTeamFeedback = async (value: string) => {
        try {
            dispatch(setLoading(true));
            await fetchTeamFeedback({
                userId: value ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Verilen geribildirimler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }
    
    useEffect(() => {
        if (teamUsers && (teamUsers as UserDto[]).length > 0) {
            var users = teamUsers as UserDto[]
            // @ts-ignore
            setSelectedMember(users[0].id); // İlk elemanı seçili yap
        }
    }, [teamUsers]);
    
    const handleFeedbackClick = (record: any) => {
        setFeedbackData(record);
        setIsFeedbackPopupVisible(true);
    };

    const handleMemberChange = (value: string) => {
        setSelectedMember(value);
        getTeamFeedback(value)
    };

    const columns = [
        { title: 'Geri Bildirim Veren', dataIndex: 'fromUserName', key: 'fromUserName' },
        { title: 'Neden', dataIndex: 'feedbackReason', key: 'feedbackReason' },
        { title: 'Tarih', dataIndex: 'date', key: 'date',
            render: (_: any, record: any) => moment(record.feedbackDate).format('DD.MM.YYYY'),
        }
    ];
    
    const getSelectedName = () => {
        var user = (teamUsers as UserDto[]).find((m) => m.id === selectedMember)
        return `${user?.firstName} ${user?.lastName}`
    }

    return (
        <div>
            <Select
                placeholder="Ekip üyesi seçin"
                style={{ width: 300, marginBottom: 16 }}
                onChange={handleMemberChange}
                value={selectedMember || undefined} // Seçili eleman
                showSearch={true}
                filterOption={(input, option) =>
                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                }
                options={((teamUsers ?? []) as UserDto[]).map(k => {
                    return {value: k.id, label: `${k.firstName} ${k.lastName} ${k.title ? "(" + k.title + ")" : ""}`};
                    }
                )}
            />
            
            {selectedMember && (
                <Table
                    dataSource={teamFeedback}
                    columns={columns}
                    rowKey="id"
                    title={() => `Ekibimin Aldığı Geri Bildirimler (${getSelectedName()})`}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => handleFeedbackClick(record),
                    })}
                />
            )}

            {/* Geri Bildirim Popup */}
            {feedbackData && (
                <FeedbackPopup
                    visible={isFeedbackPopupVisible}
                    data={feedbackData}
                    onClose={() => setIsFeedbackPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default TeamFeedbacks;
