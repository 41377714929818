export interface PerformanceResultType {
    name: string;
    icon: string;
}

export class PerformanceResultTypes {
    static GetType = (result: number) => {
        switch (result) {
            case 1 :
                return {
                    name: "Üstün Başarılı",
                    icon: "🤩"
                } as PerformanceResultType

            case 2:
                return {
                    name: "Başarılı",
                    icon: "😀"
                } as PerformanceResultType

            case 3:
                return {
                    name: "Sıradan",
                    icon: "😐"
                } as PerformanceResultType

            case 4:
                return {
                    name: "Daha İyi Olmalı",
                    icon: "😑"
                } as PerformanceResultType

            case 5:
                return {
                    name: "Gelişim İhtiyacı",
                    icon: "😔"
                } as PerformanceResultType
        }
    }

}