import React, {useEffect, useState} from 'react';
import {IDetailItem} from "../data";
import {Badge, Modal, Spin, Table} from "antd";
import TeamPerformanceCardModal from "../../TeamsOKRs/Components/TeamPerformanceCardModal";
import {
    ActivePerformanceCardDto,
    useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery
} from "../../../api/services/performanceCards";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import moment from "moment/moment";
import {UserDto} from "../../../api/services/user";

export interface IPerformanceResultsCards {
    selectedItem: IDetailItem | undefined
}

const PerformanceResultsCards: React.FC<IPerformanceResultsCards> = ({selectedItem}) => {
    const [fetchPerformanceCards, {data: performanceCardList}] = useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery()
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCard, setSelectedCard] = useState<ActivePerformanceCardDto | null>(null);
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        if (user) {
            getCardList(selectedItem)
        }
    }, [selectedItem])

    const getCardList = async (value: any) => {
        try {
            setLoading(true)

            await fetchPerformanceCards({
                userId: value?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('kartlar sorgulanırken hata oluştu')));
        } finally {
            setLoading(false)
        }
    }

    const handleRowClick = (record: any) => {
        setSelectedCard(record);
        setModalVisible(true);
    };


    const columns = [
        {title: 'Kart Adı', dataIndex: 'cardName', key: 'cardName'},
        {
            title: 'Durum', dataIndex: 'periodName', key: 'periodName', render: (text: any, rec: any) => {
                return getStatusBadge(rec)
            }
        },
    ];

    const getStatusBadge = (record: any) => {
        const now = moment();

        if (moment(record.endDate).isBefore(now)) {
            return <Badge status="error" text={t('bitti')}/>;
        } else if (moment(record.startDate).isAfter(now)) {
            return <Badge status="warning" text={t('baslayacak')}/>;
        } else {
            return <Badge status="success" text={t('devam-ediyor')}/>;
        }
    };
    const handleCancel = () => {
        setModalVisible(false);
    };


    return (<>
        <Spin spinning={isLoading}>
            <Table
                dataSource={performanceCardList}
                columns={columns}
                rowKey="id"
                pagination={false}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />
        </Spin>
        <Modal
            title={selectedCard?.cardName ?? ""}
            visible={modalVisible}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
            width={1000}
            destroyOnClose={true}
        >
            <TeamPerformanceCardModal card={selectedCard}/>
        </Modal>
    </>)
};

export default PerformanceResultsCards;
