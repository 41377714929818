import {Space, Form, Select, Button} from "antd";
import row from "antd/es/row";
import {FilterRow} from "./SuitableUsers";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {PlusOutlined, MinusOutlined, FileExcelOutlined} from '@ant-design/icons';
import {GetApiParameterFilteredParametersApiResponse, ParameterDto} from "../../api/services/parameters";
import {t} from "i18next";
import {TargetAudienceDto} from "../../api/services/targetAudience";

interface SuitableUserRowProps {
    index: number
    filterRows: FilterRow[]
    setFilterRows: (row: FilterRow[]) => void
    onFilterChange: (filter: FilterRow[]) => void
    row: FilterRow
    parameterData?: GetApiParameterFilteredParametersApiResponse
    initialData: TargetAudienceDto | null
}

const SuitableUserRow: React.FC<SuitableUserRowProps> = ({
                                                             index,
                                                             filterRows,
                                                             setFilterRows,
                                                             onFilterChange,
                                                             row,
                                                             parameterData,
                                                             initialData
                                                         }) => {
    const {Option} = Select;
    const [selectedGroup, setSetselectedGroup] = useState("")
    const [filterValues, setFilterValues] = useState<ParameterDto[]>([])

    useEffect(() => {
        if (initialData && parameterData) {
            // @ts-ignore
            handleVariableChange(index, 'value', initialData?.filters?.[index]?.variable);
            // @ts-ignore
            handleFilterChange(index, 'value', initialData?.filters?.[index]?.value)
        }
    }, [initialData, parameterData])

    const handleAddRow = () => {
        setFilterRows([...filterRows, {action: 'add', variable: '', value: []}]);
    };

    const handleRemoveRow = (index: number) => {
        const newFilterRows = filterRows.filter((_, i) => i !== index);
        setFilterRows(newFilterRows);
    };

    const handleFilterChange = (index: number, field: keyof FilterRow, value: any) => {
        const newFilterRows = [...filterRows];
        newFilterRows[index] = {...newFilterRows[index], [field]: value};
        setFilterRows(newFilterRows);
        onFilterChange(newFilterRows); // Filtre değişikliklerini üst bileşene ilet
    };

    const handleVariableChange = (index: number, field: keyof FilterRow, value: any) => {
        handleFilterChange(index, 'variable', value)
        setSetselectedGroup(value)
    }

    useEffect(() => {
        if (parameterData != undefined && parameterData != null && initialData == null) {
            handleFilterChange(index, 'variable', "Department")
            setSetselectedGroup("Department")
        }
    }, [parameterData])

    useEffect(() => {
        if (selectedGroup != "") {
            var variable = initialData?.filters?.[index]?.variable
            // @ts-ignore
            if ((initialData != null && selectedGroup != variable) || initialData == null)
                handleFilterChange(index, 'value', [])

            var params = parameterData?.filter(k => k.parameterCode == selectedGroup)
                .flatMap(k => k.parameters)

            // @ts-ignore
            setFilterValues(params)

        }
    }, [selectedGroup])

    // @ts-ignore
    return (
        <Space key={index} style={{display: 'flex', marginBottom: 10}} align="baseline">
            <Form.Item style={{marginBottom: 0}}>
                <Select
                    value={row.action}
                    onChange={(value) => handleFilterChange(index, 'action', value)}
                    style={{width: 80}}
                >
                    <Option value="add">Ekle</Option>
                    <Option value="subtract">Çıkar</Option>
                </Select>
            </Form.Item>
            <Form.Item style={{marginBottom: 0}}>
                <Select
                    placeholder="Organizasyon Değişkeni"
                    value={row.variable}
                    onChange={(value) => handleVariableChange(index, 'variable', value)}
                    style={{width: 200}}
                >
                    {
                        parameterData?.map(k =>
                            <Option value={k.parameterCode}>{t(k.parameterCode?.toLowerCase() ?? "")}</Option>
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item style={{marginBottom: 0}}>
                <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                        `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                    }
                    mode="multiple"
                    placeholder="Değişken Seçimi"
                    value={row.value}
                    onChange={(value) => handleFilterChange(index, 'value', value)}
                    style={{width: 330}}
                    options={filterValues.map(k => {
                            return {value: k.id, label: k.parameterExtraInfo};
                        }
                    )}
                />
            </Form.Item>
            <Form.Item style={{marginBottom: 0}}>
                <Button type="dashed" onClick={handleAddRow} icon={<PlusOutlined/>}/>
                {index > 0 && (
                    <Button type="dashed" onClick={() => handleRemoveRow(index)} icon={<MinusOutlined/>}
                            style={{marginLeft: 10}}/>
                )}
            </Form.Item>
        </Space>
    )
        ;
}

export default SuitableUserRow;
