import React, {useEffect, useState} from 'react';
import {IDetailItem} from "../data";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../../api/services/user";
import {useLazyGetApiFeedbackGetTeamFeedbacksByUserIdQuery} from "../../../api/services/Feedback";
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import moment from "moment";
import {Select, Spin, Table} from "antd";
import FeedbackPopup from "../../../components/Feedback/FeedbackPopup";

export interface IReportDetailFeedbacks {
    selectedItem: IDetailItem | undefined
}

const ReportDetailFeedbacks: React.FC<IReportDetailFeedbacks> = ({selectedItem}) => {
    const [feedbackData, setFeedbackData] = useState(null);
    const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [fetchTeamFeedback, {data: teamFeedback}] = useLazyGetApiFeedbackGetTeamFeedbacksByUserIdQuery()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (user) {
            getTeamFeedback(selectedItem?.id ?? "")
        }
    }, [selectedItem])

    const getTeamFeedback = async (value: string) => {
        try {
            setLoading(true)
            await fetchTeamFeedback({
                userId: value ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Verilen geribildirimler çekilirken hata oluştu')));
        } finally {
            setLoading(false)
        }
    }

    const handleFeedbackClick = (record: any) => {
        setFeedbackData(record);
        setIsFeedbackPopupVisible(true);
    };

    const columns = [
        {title: 'Geri Bildirim Veren', dataIndex: 'fromUserName', key: 'fromUserName'},
        {title: 'Neden', dataIndex: 'feedbackReason', key: 'feedbackReason'},
        {
            title: 'Tarih', dataIndex: 'date', key: 'date',
            render: (_: any, record: any) => moment(record.feedbackDate).format('DD.MM.YYYY'),
        }
    ];

    return (
        <div>
            <Spin spinning={loading}>
                <Table
                    dataSource={teamFeedback}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => handleFeedbackClick(record),
                    })}
                />
            </Spin>
            {/* Geri Bildirim Popup */}
            {feedbackData && (
                <FeedbackPopup
                    visible={isFeedbackPopupVisible}
                    data={feedbackData}
                    onClose={() => setIsFeedbackPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default ReportDetailFeedbacks;