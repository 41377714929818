// src/components/PerformanceGoalEntry/PerformanceGoalEntryModal.tsx
import React, {useEffect, useState} from 'react';
import {Modal, Form, Button, Popover, FormInstance, Select} from 'antd';
import DepartmentOKRGoalTable from './DepartmentOKRGoalTable';
import FormItemCombobox from '../../components/FormItemCombobox';
import {InfoCircleOutlined} from '@ant-design/icons';
import {PerformanceSystemDto, useLazyGetApiPeriodGetActivePeriodsQuery} from '../../api/services/period';
import {DataItem} from '../../models/DataItem';
import {t} from 'i18next';
import {useSelector, useDispatch} from 'react-redux';
import {setLoading, setError} from '../../features/app/appSlice';
import {RootState} from '../../app/store';
import {ParameterType, usePostApiParameterGetParametersMutation} from '../../api/services/parameters';
import {usePostApiPerformanceGoalSavePerformanceGoalsMutation} from '../../api/services/PerformanceGoal';
import {useLazyGetApiPerformanceSystemQuery} from '../../api/services/performanceSystem';
import {
    StrategicOkrMasterDto,
    useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery
} from '../../api/services/StrategicOKR';
import moment from 'moment';
import {
    DepartmentOkrDetailDto, DepartmentOkrMasterDto, useDeleteApiDepartmentOkrDeleteDepartmentOkrDetailByIdMutation,
    usePostApiDepartmentOkrSaveDepartmentOkrMutation, usePutApiDepartmentOkrUpdateDepartmentOkrByIdMutation
} from '../../api/services/DepartmentOKR';

interface DepartmentOKREntryModalProps {
    visible: boolean;
    onClose: () => void;
    onUpdate: () => void;
    selectedRow: DepartmentOkrMasterDto | undefined
    form: FormInstance
}

const DepartmentOKREntryModal: React.FC<DepartmentOKREntryModalProps> = ({
                                                                             selectedRow,
                                                                             visible,
                                                                             onClose,
                                                                             onUpdate,
                                                                             form
                                                                         }) => {
        const [fetchStrategicOKRs, {data: strategicOKRs}] = useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery()
        const [getParameters, {data: parameters}] = usePostApiParameterGetParametersMutation()
        const [savePerformanceGoalOnService] = usePostApiDepartmentOkrSaveDepartmentOkrMutation()
        const [updatePerformanceGoalOnService] = usePutApiDepartmentOkrUpdateDepartmentOkrByIdMutation()
        const [fetchPerformanceSystems, {data: performanceSystems}] = useLazyGetApiPerformanceSystemQuery()
        const [deleteDepartmentOKRDetail] = useDeleteApiDepartmentOkrDeleteDepartmentOkrDetailByIdMutation()

        const user = useSelector((state: RootState) => state.auth.user);
        const dispatch = useDispatch();
        const [SelectedPerformanceSystem, setSelectedPerformanceSystem] = useState<string | undefined>()
        const [selectedOKR, setSelectedOKR] = useState<StrategicOkrMasterDto | undefined>()
        const [initialValues, setInitialValues] = useState<any | undefined>()

        useEffect(() => {
            if (selectedRow) {
                var v = {
                    ...selectedRow,
                    okrId: selectedRow?.strategicOKRId ?? undefined,
                    rows: selectedRow?.okrDetails?.map(k => ({
                        departmentOkr: k.departmentOKR ?? "",
                        okr: k.strategicOKRDetailId ?? "",
                        weight: k.okrWeight ?? "",
                        source: k.targetResult ?? "",
                        goalMeasurementSource: k.targetSource ?? ""
                    }))
                }
                setInitialValues(v)
                form.setFieldsValue(v)

                var okr = strategicOKRs?.find(k => k.id == v.okrId)
                setSelectedOKR(okr)
            } else {
                form.resetFields()
                form.setFieldsValue(undefined)
                setInitialValues(undefined)
            }
        }, [selectedRow])

        useEffect(() => {
            if (user) {
                getStrategicOKRs()
                getPerformanceSystems()
                getParameters({
                    companyGroupId: user.company?.companyGroupId ?? "",
                    body: [
                        7 as ParameterType, //HEDEF_OLCUM_KAYNAGI
                        0 as ParameterType
                    ]
                })
            }
        }, [user])

        const getStrategicOKRs = async () => {
            try {
                dispatch(setLoading(true));
                await fetchStrategicOKRs({
                    companyGroupId: user?.company?.companyGroupId ?? ""
                }).unwrap()
            } catch (err: any) {
                if (err.status != "PARSING_ERROR")
                    dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
            } finally {
                dispatch(setLoading(false));
            }
        }

        const getPerformanceSystems = async () => {
            try {
                dispatch(setLoading(true));
                await fetchPerformanceSystems({
                    companyGroupId: user?.company?.companyGroupId ?? ""
                }).unwrap()
            } catch (err: any) {
                if (err.status != "PARSING_ERROR")
                    dispatch(setError(t('Performans sistemleri sorgulanırken hata oluştu')));
            } finally {
                dispatch(setLoading(false));
            }
        }

        const saveDepartmentOKR = async (values: any) => {
            try {
                dispatch(setLoading(true));

                var payload = {
                    companyGroupId: user?.company?.companyGroupId ?? "",
                    functionId: values.functionId ?? "",
                    strategicOKRId: values.okrId ?? "",

                    okrDetails: (values.rows ?? []).map((k: any) => ({
                        departmentOKR: k.departmentOkr,
                        strategicOKRDetailId: k.okr,
                        okrWeight: k.weight,
                        targetResult: k.source,
                        targetSource: k.goalMeasurementSource
                    } as DepartmentOkrDetailDto))
                }

                if (selectedRow) {
                    await updatePerformanceGoalOnService({
                        id: selectedRow?.id ?? "",
                        saveDepartmentOkrdto: payload
                    })
                        .unwrap()
                } else {
                    await savePerformanceGoalOnService({
                        saveDepartmentOkrdto: payload
                    }).unwrap()
                }
                onUpdate()
                onClose()
            } catch
                (err: any) {
                if (err.status != "PARSING_ERROR")
                    dispatch(setError(t('Performans hedefi kaydedilirken hata oluştu')));
            } finally {
                dispatch(setLoading(false));
            }
        }

        const onComplete = () => {
            form.submit()
        }

        const onSave = (values: any) => {
            saveDepartmentOKR(values)
        }
        const content = (
            <div style={{width: 300}}>
                <p>Bu sayfada performans sistemlerini tanımlayabilir ve yönetebilirsiniz.</p>
                <p>OKR veya KPI bazlı performans sistemlerini burada tanımlayarak, ilgili parametreleri
                    belirleyebilirsiniz.</p>
                <p>Sistemin nasıl çalıştığını ve hangi parametrelerin gerekli olduğunu burada yapılandırabilirsiniz.</p>
            </div>
        );

        return (
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}
                title={
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span>{initialValues?.okrId != undefined ? "Departman OKR Güncelle" : "Departman OKR Girişi"}</span>
                        <Popover content={content} title="Sayfa Bilgisi" trigger="click">
                            <Button
                                shape="circle"
                                icon={<InfoCircleOutlined/>}
                                style={{
                                    marginRight: 30,
                                    marginTop: -8,
                                    zIndex: 1000,
                                }}
                            />
                        </Popover>
                    </div>
                }
                onCancel={onClose}
                footer={[
                    <Button key="cancel" onClick={onClose}>
                        İptal
                    </Button>,
                    <Button key="save" type="primary" onClick={onComplete}>
                        Kaydet
                    </Button>,
                ]}
                width={1200}
                bodyStyle={{maxHeight: '70vh', overflowY: 'auto'}}
            >
                <Form form={form} layout="vertical"
                      onFinish={onSave}>
                    <div className='flex flex-row'>
                        <Form.Item name={"okrId"}
                                   label={"Stratejik OKR'lar"}
                                   className={"w-[500px]"}
                                   rules={[
                                       {required: true, message: "Bir Stratejik OKR Seçmelisiniz"}
                                   ]}>
                            <Select
                                onChange={l => {
                                    var okr = strategicOKRs?.find(k => k.id == l)
                                    setSelectedOKR(okr)
                                }}
                                className={"w-[500px] h-[60px]"}
                                placeholder={"Stratejik OKR Seçiniz"}
                                showSearch={true}
                                filterOption={(input, option) =>
                                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                }
                                disabled={initialValues?.okrId != null}
                                options={(strategicOKRs ?? [])
                                    .map(k => {
                                            return {
                                                value: k.id,
                                                label: `${k.performanceSystem?.systemName} ${k.period?.periodName} (${moment(k.period?.startDate).format("DD/MM/YYYY")}-${moment(k.period?.endDate).format("DD/MM/YYYY")})`
                                            };
                                        }
                                    )}
                                optionRender={(oriOption: any, info: any) => {
                                    var i = info.index
                                    var okr = (strategicOKRs ?? [])[i]
                                    return (
                                        <div className={"flex flex-col h-[50px] p-1"}>
                                            <text>{okr?.performanceSystem?.systemName}</text>
                                            <text>{okr?.period?.periodName} ({moment(okr?.period?.startDate).format("DD/MM/YYYY")}-{moment(okr?.period?.endDate).format("DD/MM/YYYY")})</text>
                                        </div>
                                    )
                                }}
                                labelRender={(props) => {
                                    var id = props.value
                                    var okr = (strategicOKRs ?? []).find(k => k.id == id)

                                    return <div className={"flex flex-col h-[50px] p-0 mt-1"}>
                                        <p className={"text-[15px] h-[15px]"}>{okr?.performanceSystem?.systemName}</p>
                                        <p className={"text-[15px] h-[15px] mt-1"}>{okr?.period?.periodName} ({moment(okr?.period?.startDate).format("DD/MM/YYYY")}-{moment(okr?.period?.endDate).format("DD/MM/YYYY")})</p>
                                    </div>
                                }}
                            />
                        </Form.Item>
                        <Form.Item name={"functionId"}
                                   label={"Fonksiyon"}
                                   className={"w-[500px] ml-[20px]"}
                                   rules={[
                                       {required: true, message: "Bir Fonksiyon Seçmelisiniz"}
                                   ]}>
                            <Select
                                onChange={l => {
                                    setSelectedPerformanceSystem(l.id)
                                }}
                                className={"w-[500px] h-[60px]"}
                                placeholder={"Fonksiyon Seçiniz"}
                                showSearch={true}
                                filterOption={(input, option) =>
                                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                }
                                disabled={initialValues?.okrId != null}
                                options={(parameters ?? []).filter(k => k.parameterCode == "Function").map(k => ({
                                    value: k.id,
                                    label: k.parameterValue
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <DepartmentOKRGoalTable
                        goal={initialValues}
                        selectedOKR={selectedOKR}
                        deleteRow={async (id: string) => {
                            await deleteDepartmentOKRDetail({
                                id: id
                            }).unwrap()

                            onUpdate()
                        }}/>
                </Form>
            </Modal>
        );
    }
;

export default DepartmentOKREntryModal;
