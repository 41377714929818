import {Button, Card, Progress, Slider, Space} from "antd";
import {
    ActivePerformanceCardDto,
    PerformanceCardDetailsDto,
    useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery,
    usePostApiPerformanceCardsAddUserOkrMutation,
    UserKrDetailsDto,
    UserOkrDetailsDto
} from "../../../api/services/performanceCards";
import PerformanceCardKR from "./PerformanceCardKR";
import {
    EditOutlined,
    EyeInvisibleOutlined
} from '@ant-design/icons';
import {useEffect, useState} from "react";
import KRInputModal from "./Modal/KRInputModal";
import {t} from "i18next";
import {useSelector, useDispatch} from "react-redux";
import {setLoading, setError} from "../../../features/app/appSlice";
import {RootState} from "../../../app/store";
import {LinkedOKRId, WeightenedOKRId} from "../../../data/constants";
import moment from "moment";
import {PerformanceCardGroupType} from "../PerformanceCardGroupType";

export interface IPerformanceCardOKR {
    CardDetails: PerformanceCardDetailsDto | undefined
    index: number
    okr: UserOkrDetailsDto | undefined
    update: () => void
    weight?: number | null | undefined
    progressUpdate: () => void
    type: PerformanceCardGroupType
}

const PerformanceCardOKR: React.FC<IPerformanceCardOKR> = ({
                                                               CardDetails,
                                                               index,
                                                               okr,
                                                               update,
                                                               weight,
                                                               progressUpdate,
                                                               type
                                                           }) => {
    const [krInputActive, setKrInputActive] = useState(false)
    const [isWeightened, setIsWeightened] = useState(false)
    const [updateCard, setUpdateCard] = useState<UserKrDetailsDto | null>(null)
    const [krs, setKrs] = useState<UserKrDetailsDto[]>([])

    useEffect(() => {
        if (updateCard)
            setKrInputActive(true)
    }, [updateCard])

    useEffect(() => {
        if (okr) {
            setKrs(okr.kRs ?? [])

            var okrWeightId = CardDetails?.performanceSystem?.okrDetails?.[0].okrWeightId
            setIsWeightened(okrWeightId == WeightenedOKRId)
        }
    }, [okr])

    return (
        <Card title={
            <div className="flex flex-row justify-between items-center bg-[#B3C8CF] rounded-md overflow-hidden">
                <div className="flex flex-row">
                    <div className="bg-[#89A8B2] w-[30px] -my-[3px]">
                        <h2 className="text-center text-white mt-[2px]">{index + 1}</h2>
                    </div>
                    <h2 className="text-white ml-3 mr-5">{okr?.description}</h2>
                    <div className=" flex flex-row h-[30px]">

                        {isWeightened ?
                            <Progress type="circle"
                                      strokeColor={"#00FF00"}
                                      trailColor="#00000030"
                                      format={(percent) => `${percent}%`}
                                      status={"normal"}
                                      percent={okr?.weight ?? 0}
                                      size={30}/>
                            : <></>}
                    </div>

                </div>
                <div className="flex flex-row items-center">
                    {
                        krs.length > 0 && type == PerformanceCardGroupType.Active &&
                        <Progress percent={Number((okr?.progress ?? 0).toFixed(2))} strokeColor={"#00FF00"}
                                  trailColor="#00000030" percentPosition={{align: 'start', type: 'outer'}}
                                  className="w-[200px]"/>
                    }


                    <div className="flex flex-row ml-2">
                        {okr?.isPrivate == true &&
                            <Button type="primary" onClick={() => {
                            }} className="text-black bg-transparent border-0 rounded-none">
                                <EyeInvisibleOutlined/>
                            </Button>
                        }
                        {type == PerformanceCardGroupType.Active &&
                            <Button type="primary" onClick={() => {
                                update()
                            }} className="text-black bg-transparent border-0 rounded-none">
                                <EditOutlined/>
                            </Button>
                        }
                        {type == PerformanceCardGroupType.Active &&
                            <Button type="primary" onClick={() => {
                                setKrInputActive(true)
                            }} className="text-white bg-gray-500 border-0 rounded-none">
                                KR Ekle
                            </Button>
                        }
                    </div>
                </div>
            </div>
        }
              bordered={false}
              className="w-full mt-2 pr-0"
              style={{borderRadius: 0, boxShadow: "none", background: "transparent"}}
              styles={{
                  body: {
                      paddingLeft: 10,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 10
                  },
                  header: {
                      height: 30,
                      minHeight: 40,
                      border: 0,
                      padding: 0,
                  }
              }}>

            <Space direction="vertical" size={"small"} className="w-full">
                {[...krs]
                    .sort(function (left, right) {
                        return moment.utc(left.createdDate).diff(moment.utc(right.createdDate))
                    })
                    .map((k: UserKrDetailsDto, i) => (
                        <PerformanceCardKR index={i}
                                           card={CardDetails}
                                           kr={k}
                                           type={type}
                                           update={() => {
                                               setUpdateCard(k)
                                           }}
                                           progressUpdate={() => {
                                               progressUpdate()
                                           }}/>
                    ))}
            </Space>

            <KRInputModal
                initialValue={updateCard}
                isActive={krInputActive}
                setActive={(status) => {
                    setKrInputActive(status)
                    if (!status) {
                        setUpdateCard(null)
                    }
                }}
                OKRId={okr?.okrId ?? ""}
                newOKRAdded={kr => {
                    setKrs([...krs, kr])
                    setKrInputActive(false)
                    progressUpdate()
                }}
                okrUpdated={kr => {
                    var oldKR = krs.find(k => k.krId == kr.id)
                    var filteredKRs = krs.filter(k => k.krId != kr.id)
                    kr.createDate = oldKR?.createdDate
                    setKrs([...filteredKRs, kr])
                    setKrInputActive(false)
                }}
                updateScreen={() => {
                    setUpdateCard(null)
                    setKrInputActive(false)
                    progressUpdate()
                }}
            />
        </Card>
    );
}

export default PerformanceCardOKR;
