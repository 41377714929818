import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiOneToOneMeetingGetMeetingsByManagerByManagerId: build.query<
      GetApiOneToOneMeetingGetMeetingsByManagerByManagerIdApiResponse,
      GetApiOneToOneMeetingGetMeetingsByManagerByManagerIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OneToOneMeeting/GetMeetingsByManager/${queryArg.managerId}`,
        params: { periodId: queryArg.periodId },
      }),
    }),
    getApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeId: build.query<
      GetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdApiResponse,
      GetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OneToOneMeeting/GetMeetingsByEmployee/${queryArg.employeeId}`,
        params: { periodId: queryArg.periodId },
      }),
    }),
    getApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberId: build.query<
      GetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdApiResponse,
      GetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OneToOneMeeting/GetMeetingsForTeamMember/${queryArg.teamMemberId}`,
        params: { periodId: queryArg.periodId },
      }),
    }),
    postApiOneToOneMeetingAddMeeting: build.mutation<
      PostApiOneToOneMeetingAddMeetingApiResponse,
      PostApiOneToOneMeetingAddMeetingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OneToOneMeeting/AddMeeting`,
        method: "POST",
        body: queryArg.saveOneToOneMeetingDto,
        params: { managerId: queryArg.managerId, userId: queryArg.userId },
      }),
    }),
    postApiOneToOneMeetingUpdateMeeting: build.mutation<
      PostApiOneToOneMeetingUpdateMeetingApiResponse,
      PostApiOneToOneMeetingUpdateMeetingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OneToOneMeeting/UpdateMeeting`,
        method: "POST",
        params: {
          meetingId: queryArg.meetingId,
          comment: queryArg.comment,
          role: queryArg.role,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiOneToOneMeetingGetMeetingsByManagerByManagerIdApiResponse =
  /** status 200 OK */ OneToOneMeetingDto[];
export type GetApiOneToOneMeetingGetMeetingsByManagerByManagerIdApiArg = {
  managerId: string;
  periodId?: string;
};
export type GetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdApiResponse =
  /** status 200 OK */ OneToOneMeetingDto[];
export type GetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdApiArg = {
  employeeId: string;
  periodId?: string;
};
export type GetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdApiResponse =
  /** status 200 OK */ OneToOneMeetingDto[];
export type GetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdApiArg =
  {
    teamMemberId: string;
    periodId?: string;
  };
export type PostApiOneToOneMeetingAddMeetingApiResponse = unknown;
export type PostApiOneToOneMeetingAddMeetingApiArg = {
  managerId?: string;
  userId?: string;
  saveOneToOneMeetingDto: SaveOneToOneMeetingDto;
};
export type PostApiOneToOneMeetingUpdateMeetingApiResponse = unknown;
export type PostApiOneToOneMeetingUpdateMeetingApiArg = {
  meetingId?: string;
  comment?: string;
  role?: string;
};
export type OneToOneMeetingDto = {
  id?: string;
  periodId?: string;
  periodName?: string | null;
  managerId?: string;
  managerName?: string | null;
  employeeId?: string;
  employeeName?: string | null;
  meetingTypeId?: string;
  meetingTypeName?: string | null;
  meetingNameId?: string;
  meetingName?: string | null;
  meetingDate?: string;
  managerComment?: string | null;
  employeeComment?: string | null;
  status?: string | null;
  initiatorName?: string | null;
  initiatorId?: string;
};
export type SaveOneToOneMeetingDto = {
  periodId?: string;
  employeeId?: string;
  meetingTypeId?: string;
  meetingNameId?: string;
  meetingDate?: string;
  comment?: string | null;
};
export const {
  useGetApiOneToOneMeetingGetMeetingsByManagerByManagerIdQuery,
  useLazyGetApiOneToOneMeetingGetMeetingsByManagerByManagerIdQuery,
  useGetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdQuery,
  useLazyGetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdQuery,
  useGetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdQuery,
  useLazyGetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdQuery,
  usePostApiOneToOneMeetingAddMeetingMutation,
  usePostApiOneToOneMeetingUpdateMeetingMutation,
} = injectedRtkApi;
