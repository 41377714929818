import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "antd";
import {Pie} from "@ant-design/charts";

const PlotMaps = {};

const PieChart3: React.FC = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        setData([
            {"title": "Tamamlananlar", "ratio": 18, "color": "#00CCCC"},
            {"title": "Devam Edenler", "ratio": 78, "color": "#D1E231"},
            {"title": "İptal Edilenler", "ratio": 4, "color": "#C0C0C0"},
        ])
    }, []);

    if (!Object.keys(data).length) {
        return null;
    }

    // @ts-ignore
    const showTooltip = (evt, pie) => {
        Object.keys(PlotMaps).forEach((plot) => {
            if (plot !== pie) {
                // @ts-ignore
                PlotMaps[plot].chart.emit('tooltip:show', {
                    data: {data: {area: evt.data.data.area}},
                });
                // @ts-ignore
                PlotMaps[plot].chart.emit('element:highlight', {
                    data: {data: {area: evt.data.data.area}},
                });
            }
        });
    };

    // @ts-ignore
    const hideTooltip = (evt, pie) => {
        Object.keys(PlotMaps).forEach((plot) => {
            if (plot !== pie) {
                // @ts-ignore
                PlotMaps[plot].chart.emit('tooltip:hide', {
                    data: {data: {area: evt.data.data.area}},
                });
                // @ts-ignore
                PlotMaps[plot].chart.emit('element:unhighlight', {
                    data: {data: {area: evt.data.data.area}},
                });
            }
        });
    };


    const LeftConfig = {
        angleField: 'ratio',
        colorField: 'title',
        // @ts-ignore
        data: data,
        scale: {
            color: { range: ['#00CCCC', '#D1E231', '#C0C0C0'] },
        },
        label: {
            text: (d: any) => `${d.ratio}%`,
            position: "inside",
            style: {
                fontWeight: 'bold',
                fill: "#003262"
            },
        },
        style: {
            stroke: '#fff',
            inset: 1,
            radius: 10,
        },
        innerRadius: 0.6,
        legend: {
            color: {
                title: true,
                position: 'bottom',
                rowPadding: 5,
            },
        },
        tooltip: {
            title: 'title',
            items: [{name: '', field: "ratio", valueFormatter: (d: any) => `${d} %`}],
        },
        interaction: {
            elementHighlight: true,
        },
        state: {
            inactive: {opacity: 0.5},
        },
    };

    return (<>
        <Card title={
            <div>
                <h3>KR Durumları (Hedef Tamamlamalara Göre)</h3>
            </div>
        }
              styles={{
                  header: {
                      backgroundColor: "#89A8B2"
                  }
              }}
              bordered={true}>


            <Pie {...LeftConfig} />

        </Card>

    </>)
};

export default PieChart3;