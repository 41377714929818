import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiDialogParametersByCompanyGroupId: build.query<
      GetApiDialogParametersByCompanyGroupIdApiResponse,
      GetApiDialogParametersByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Dialog/parameters/${queryArg.companyGroupId}`,
      }),
    }),
    postApiParameterUpdateParameters: build.mutation<
      PostApiParameterUpdateParametersApiResponse,
      PostApiParameterUpdateParametersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Parameter/update-parameters`,
        method: "POST",
        params: {
          companyGroupId: queryArg.companyGroupId,
          parameterType: queryArg.parameterType,
        },
      }),
    }),
    getApiParameterList: build.query<
      GetApiParameterListApiResponse,
      GetApiParameterListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Parameter/list`,
        params: {
          companyGroupId: queryArg.companyGroupId,
          parameterGroup: queryArg.parameterGroup,
          parameterCode: queryArg.parameterCode,
        },
      }),
    }),
    postApiParameterAdd: build.mutation<
      PostApiParameterAddApiResponse,
      PostApiParameterAddApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Parameter/add`,
        method: "POST",
        body: queryArg.addParameterRequest,
      }),
    }),
    postApiParameterRemove: build.mutation<
      PostApiParameterRemoveApiResponse,
      PostApiParameterRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Parameter/remove`,
        method: "POST",
        params: { id: queryArg.id },
      }),
    }),
    getApiParameterFilteredParameters: build.query<
      GetApiParameterFilteredParametersApiResponse,
      GetApiParameterFilteredParametersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Parameter/filtered-parameters`,
        params: {
          companyGroupId: queryArg.companyGroupId,
          parameterGroup: queryArg.parameterGroup,
          companyId: queryArg.companyId,
          parameterCode: queryArg.parameterCode,
        },
      }),
    }),
    postApiParameterGetParameters: build.mutation<
      PostApiParameterGetParametersApiResponse,
      PostApiParameterGetParametersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Parameter/GetParameters`,
        method: "POST",
        body: queryArg.body,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiDialogParametersByCompanyGroupIdApiResponse = unknown;
export type GetApiDialogParametersByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiParameterUpdateParametersApiResponse = unknown;
export type PostApiParameterUpdateParametersApiArg = {
  companyGroupId?: string;
  parameterType?: ParameterType;
};
export type GetApiParameterListApiResponse = /** status 200 OK */ Parameter[];
export type GetApiParameterListApiArg = {
  companyGroupId?: string;
  parameterGroup?: ParameterType;
  parameterCode?: string;
};
export type PostApiParameterAddApiResponse = unknown;
export type PostApiParameterAddApiArg = {
  addParameterRequest: AddParameterRequest;
};
export type PostApiParameterRemoveApiResponse = unknown;
export type PostApiParameterRemoveApiArg = {
  id?: string;
};
export type GetApiParameterFilteredParametersApiResponse =
  /** status 200 OK */ ParameterGroupDto[];
export type GetApiParameterFilteredParametersApiArg = {
  companyGroupId?: string;
  parameterGroup?: string;
  companyId?: string;
  parameterCode?: string;
};
export type PostApiParameterGetParametersApiResponse =
  /** status 200 OK */ ParameterDto[];
export type PostApiParameterGetParametersApiArg = {
  companyGroupId?: string;
  body: ParameterType[];
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type AddParameterRequest = {
  companyGroupId?: string;
  parameterGroup?: ParameterType;
  parameterCode?: string | null;
  parameterValue?: string | null;
};
export type ParameterDto = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string | null;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
  parameterExtraInfo?: string | null;
};
export type ParameterGroupDto = {
  parameterCode?: string | null;
  parameters?: ParameterDto[] | null;
};
export const {
  useGetApiDialogParametersByCompanyGroupIdQuery,
  useLazyGetApiDialogParametersByCompanyGroupIdQuery,
  usePostApiParameterUpdateParametersMutation,
  useGetApiParameterListQuery,
  useLazyGetApiParameterListQuery,
  usePostApiParameterAddMutation,
  usePostApiParameterRemoveMutation,
  useGetApiParameterFilteredParametersQuery,
  useLazyGetApiParameterFilteredParametersQuery,
  usePostApiParameterGetParametersMutation,
} = injectedRtkApi;
