import React from 'react';


const StrategicOKRs: React.FC = () => {
    return (<>


    </>)
};

export default StrategicOKRs;