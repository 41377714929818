import React, {useEffect, useState} from 'react';
import WithEmployees from './WithEmployees';
import {Badge, Button, Input, Select, Table} from "antd";
import OneOnOnePopup from "../../components/OneOnOnes/OneOnOnePopup";
import OneOnOneDetailsPopup from "../../components/OneOnOnes/OneOnOneDetailsPopup";
import {
    useLazyGetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdQuery,
    useLazyGetApiOneToOneMeetingGetMeetingsByManagerByManagerIdQuery
} from "../../api/services/OneToOneMeeting";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import moment from "moment";
import {Guid} from "typescript-guid";


const {Option} = Select;
const {TextArea} = Input;

const WithManager: React.FC = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isDetailsPopupVisible, setIsDetailsPopupVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [fetchMeetings, {data: meetings}] = useLazyGetApiOneToOneMeetingGetMeetingsByEmployeeByEmployeeIdQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()

    useEffect(() => {
        if (user) {
            getMeetings()
        }
    }, [user])

    const getMeetings = async () => {
        try {
            dispatch(setLoading(true));
            await fetchMeetings({
                employeeId: user?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Görüşmeler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }
    const handleNewMeeting = () => {
        setIsPopupVisible(true);
    };

    const handleRowClick = (record: any) => {
        setSelectedRecord(record);
        setIsDetailsPopupVisible(true);
    };

    const columns = [
        {title: 'Yönetici', dataIndex: 'managerName', key: 'managerName'},
        {title: 'Dönem', dataIndex: 'periodName', key: 'periodName'},
        {title: 'Görüşme Adı', dataIndex: 'meetingName', key: 'meetingName'},
        {title: 'Tip', dataIndex: 'meetingTypeName', key: 'meetingTypeName'},
        {
            title: 'Tarih', dataIndex: 'meetingDate', key: 'meetingDate',
            render: (_: any, record: any) => moment(record.meetingDate).format('DD.MM.YYYY'),
        },
        {
            title: 'Durum', dataIndex: 'status', key: 'status',
            render: (_: any, record: any) => {
                if (record.status == 'Waiting' && !record.employeeComment) {
                    return <Badge status="error" text={t('Çalışan yorumu bekleniyor')}/>
                } else if (record.status == 'Waiting' && !record.managerComment) {
                    return <Badge status="error" text={t('Yönetici yorumu bekleniyor')}/>
                } else if (record.status == 'Completed') {
                    return <Badge status="success" text={t('Tamamlandı')}/>
                }
            }
        }
    ];

    return (
        <div>
            {user?.managerId !== Guid.createEmpty().toString() &&
                <Button type="primary" onClick={handleNewMeeting} style={{marginBottom: 16}}>
                    Görüşme Başlat
                </Button>
            }
            <Table dataSource={meetings}
                   columns={columns}
                   rowKey="id"
                   pagination={false}
                   onRow={(record) => ({
                       onClick: () => handleRowClick(record),
                   })}
            />

            <OneOnOnePopup
                visible={isPopupVisible}
                onClose={() => setIsPopupVisible(false)}
                toManager={true}
                onSave={() => {
                    getMeetings()
                }}
            />

            <OneOnOneDetailsPopup
                visible={isDetailsPopupVisible}
                onClose={() => setIsDetailsPopupVisible(false)}
                data={selectedRecord}
                toManager={true}
                onSave={(updatedData) => {
                    getMeetings()
                }}
            />
        </div>
    );
};

export default WithManager;
