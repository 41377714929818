import React from 'react';


const DepartmentOKRs: React.FC = () => {
    return (<>


    </>)
};

export default DepartmentOKRs;