import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "antd";
import {Pie} from "@ant-design/plots";
import {PieConfig} from "@ant-design/plots/es/components/pie";

const PieChart2: React.FC = () => {
    const [data, setData] = useState<Object>([]);

    useEffect(() => {
        setData([
            {"title": "Tamamlananlar", "ratio": 25},
            {"title": "Devam Edenler", "ratio": 72},
            {"title": "İptal Edilenler", "ratio": 3},
        ])
    }, []);

    if (!Object.keys(data).length) {
        return null;
    }

    
    const Config = {
        angleField: 'ratio',
        colorField: "title",
        // @ts-ignore
        data: data,
        scale: {
            color: { range: ['#32CD32', '#FFBF00', '#C0C0C0'] },
        },
        innerRadius: 0.6,
        label: {
            text: (d: any) => `${d.ratio}%`,
            position: "inside",
            style: {
                fontWeight: 'bold',
                fill: "#003262",
            },
        },
        legend: {
            color: {
                title: false,
                position: 'bottom',
                rowPadding: 5,

            },
        },
        style: {
            stroke: '#fff',
            inset: 1,
            radius: 10,
        },
        tooltip: {
            title: 'title',
            items: [{name: '', field: "ratio", valueFormatter: (d: any) => `${d} %`}],
        },
        interaction: {
            elementHighlight: true,
        },
        state: {
            inactive: {opacity: 0.5},
        },
    } as PieConfig;

    return (<>
        <Card title={
            <div>
                <h3>OKR Durumları (Hedef Tamamlamalara Göre)</h3>
            </div>
        }
              styles={{
                  header: {
                      backgroundColor: "#89A8B2"
                  }
              }}
              bordered={true}>


            <Pie {...Config} />

        </Card>

    </>)
};

export default PieChart2;