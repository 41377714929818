import React, {useEffect, useState} from 'react';
import {Avatar, Col, List, Modal, Row, Tabs} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import ReportDetailTab, {ReportDetailItemType} from "./ReportDetailTab";
import {Guid} from "typescript-guid";
import {data, IDetailItem} from "../data";

export interface IReportDetail {
    isModalActive: boolean
    toggleModal: (isActive: boolean) => void
    blockDetails: string | undefined
}



const ReportDetail: React.FC<IReportDetail> = ({
                                                   isModalActive,
                                                   toggleModal,
                                                   blockDetails
                                               }) => {
    const [isActive, setIsActive] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<IDetailItem | undefined>()

    useEffect(() => {
        setSelectedItem(data[0])
    }, []);
    useEffect(() => {
        setIsActive(isModalActive)
    }, [isModalActive]);

    const handleCancel = () => {
        setIsActive(false)
        toggleModal(false)
    };

   

    const tabs = [
        {
            label: `Performans Sonucu`,
            key: "ps1",
            children: <ReportDetailTab type={ReportDetailItemType.performanceResults} selectedItem={selectedItem}/>
        },
        {
            label: `Performans Kartları`,
            key: "ps2",
            children: <ReportDetailTab type={ReportDetailItemType.performanceCards} selectedItem={selectedItem}/>
        },
        {
            label: `1-1 Görüşmeler`,
            key: "ps3",
            children: <ReportDetailTab type={ReportDetailItemType.OntoOneMeetins} selectedItem={selectedItem}/>
        },
        {
            label: `Geri Bildirimler`,
            key: "ps4",
            children: <ReportDetailTab type={ReportDetailItemType.Feedbacks} selectedItem={selectedItem}/>
        },
    ]

    return (<>
            <Modal
                title={<>
                    <h2>{blockDetails} Kişiler</h2>
                </>}
                visible={isActive}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={1000}
                height={500}
                destroyOnClose={true}
            >
                <Row gutter={[16, 16]} className={"h-[500px] w-full "}>
                    <Col span={6} className={"bg-[#B3C8CF] rounded-md max-h-full overflow-scroll"}>
                        <List
                            className={""}
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                                <List.Item
                                    onClick={() => {
                                        setSelectedItem(item)
                                    }}>
                                    <List.Item.Meta
                                        className={`bg-[#B3C8CF] px-2 py-1 cursor-pointer transform rounded-md`}
                                        avatar={<Avatar icon={<UserOutlined/>}/>}
                                        title={`${item.name}`}
                                        description={`${item.title}`}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={18}>
                        <Tabs
                            type="card"
                            items={tabs}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    )
};

export default ReportDetail;