import React from 'react';
import {Col, Row} from "antd";
import PieChart1 from "./components/PieChart1";
import PieChart2 from "./components/PieChart2";
import PieChart3 from "./components/PieChart3";

const Dashboard: React.FC = () => {
    return (<>
        <Row gutter={[16, 16]}>
            <Col span={8}> <PieChart1 /></Col>
            <Col span={8}> <PieChart2 /></Col>
            <Col span={8}> <PieChart3 /></Col>

        </Row>


    </>)
};

export default Dashboard;