import React, {useState} from 'react';
import {Button} from "antd";
import ReportDetail from "./Components/ReportDetail";


const Reports: React.FC = () => {
    const [scaleCount, setScaleCount] = React.useState(5);
    const [selectedBlock, setSelectedBlock] = useState<string | undefined>()

    var scaleWhat = [
        "GELİŞİM ALANI ÇOK FAZLA",
        "DAHA İYİ OLMALI",
        "BAŞARILI",
        "ÜSTÜN BAŞARILI",
        "MÜKEMMEL"
    ]

    var scaleWhatMax = [
        "%10",
        "%15",
        "%70",
        "%10",
        "%0",
    ]

    var scaleHow = [
        "GELİŞİM ALANI ÇOK FAZLA",
        "DAHA İYİ OLMALI",
        "BAŞARILI",
        "ÜSTÜN BAŞARILI",
        "MÜKEMMEL"
    ]

    var scaleHowMax = [
        "%0",
        "%10",
        "%60",
        "%20",
        "%10",
    ]

    var scaleWhat = [
        "GELİŞİM ALANI ÇOK FAZLA",
        "DAHA İYİ OLMALI",
        "BAŞARILI",
        "ÜSTÜN BAŞARILI",
        "MÜKEMMEL"
    ]

    var data = [
        ["%0", "%0", "%5", "%3", "%2"],
        ["%0", "%0", "%7", "%5", "%3"],
        ["%0", "%10", "%45", "%10", "%5"],
        ["%0", "%0", "%8", "%2", "%0"],
        ["%0", "%0", "%0", "%0", "%0"]
    ]

    var colors = [
        ["#acb8c0", "#e3dbfe", "#3b6fd8", "#00cce6", "#00cce6"],
        ["#acb8c0", "#e3dbfe", "#6857c6", "#3b6fd8", "#00cce6"],
        ["#acb8c0", "#e3dbfe", "#6857c6", "#6857c6", "#3b6fd8"],
        ["#acb8c0", "#fdd1f3", "#e3dbfe", "#e3dbfe", "#e3dbfe"],
        ["#acb8c0", "#acb8c0", "#acb8c0", "#acb8c0", "#acb8c0"]
    ]

    const showDetail = (block: string) => {
        setSelectedBlock(block)
    }

    return (<>
        <div className="flex justify-center mt-8">
            <table className="border-collapse text-center rounded-lg overflow-hidden border-1 border-black ">
                <thead>
                <tr>
                    <th
                        rowSpan={2}
                        className="border border-gray-300 bg-[#00906e] font-bold text-white p-2 w-[150px]"
                    >
                        OKR DEĞERLENDİRME SONUÇLARI
                    </th>
                    <th
                        rowSpan={2}
                        className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                    >
                        SKALA ({scaleCount}'li)
                    </th>
                    <th
                        colSpan={scaleCount}
                        className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                    >
                        "NASIL YAPTIK"
                    </th>
                    <th
                        rowSpan={2}
                        className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                    >
                        MAX KOTA (NE YAPTIK)
                    </th>
                </tr>
                <tr>
                    {scaleHow.splice(0, scaleCount).map(k =>
                        <th className="border border-gray-300 bg-[#c8ede5] p-2">
                            {k}
                        </th>
                    )}
                </tr>
                </thead>
                <tbody>
                {data.slice(0, scaleCount).map((row: string[], index: number) => (
                    <tr>
                        {index == 0 &&
                            <td
                                rowSpan={scaleCount}
                                className="border border-gray-300 bg-[#c8ede5] font-bold p-2"
                            >
                                "NE YAPTIK"
                            </td>
                        }
                        <td className="border border-gray-300 bg-[#c8ede5] p-2 h-[80px] w-[150px]  font-bold">{scaleWhat[scaleCount - 1 - index]}</td>

                        {row.slice(0, scaleCount).map((col: string, index2: number) => (
                            <td
                                className="border h-[80px] w-[150px]">
                                <Button style={{
                                    backgroundColor: colors[index][index2],
                                    borderColor: "white",
                                    color: "black"
                                }}
                                        className={"w-full h-full rounded-none transform hover:scale-110 hover:text-black hover:rounded-md hover:z-50"}
                                        onClick={() => {
                                            showDetail(col)
                                        }}>
                                    {col}
                                </Button>

                            </td>
                        ))}

                        <td className="border border-gray-300 p-2 h-[80px] w-[150px]  font-bold">{scaleWhatMax[index]}</td>
                    </tr>
                ))}
                <tr>
                    <td
                        colSpan={2}
                        className="border border-gray-300 bg-[#c8ede5] font-bold p-2 h-[80px]"
                    >
                        MAX KOTA (NASIL YAPTIK)
                    </td>

                    {scaleHowMax.slice(0, scaleCount).map(col => (
                        <td className="border border-gray-300 p-2 font-bold">{col}</td>
                    ))}

                    <td className="border border-gray-300 p-2 font-bold">%100</td>
                </tr>
                </tbody>
            </table>
        </div>
        <ReportDetail
            isModalActive={selectedBlock !== undefined}
            toggleModal={() => setSelectedBlock(undefined)}
            blockDetails={selectedBlock}
        />
    </>)
};

export default Reports;