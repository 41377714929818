import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../api/services/user";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import moment from "moment";
import {Badge, Modal, Select, Table} from "antd";
import {
    ActivePerformanceCardDto,
    useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery
} from "../../api/services/performanceCards";
import PerformanceCardModal from "../MyPerformanceCardsScreen/Components/PerformanceCardModal";
import TeamPerformanceCardModal from "./Components/TeamPerformanceCardModal";


const TeamsOKRs: React.FC = () => {
    const [selectedMember, setSelectedMember] = useState<string | null>(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [getTeamUsers, {data: teamUsers}] = useLazyGetApiUserTeamMembersQuery()
    const [fetchPerformanceCards, {data: performanceCardList}] = useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery()
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCard, setSelectedCard] = useState<ActivePerformanceCardDto | null>(null);
    
    useEffect(() => {
        if (user) {
            FetchTeamUsers()
        }
    }, [user])


    const FetchTeamUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getTeamUsers().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getCardList = async (value: any) => {
        try {
            dispatch(setLoading(true));

            await fetchPerformanceCards({
                userId: value ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('kartlar sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if (teamUsers && (teamUsers as UserDto[]).length > 0) {
            var users = teamUsers as UserDto[]
            // @ts-ignore
            handleMemberChange(users[0].id); // İlk elemanı seçili yap
        }
    }, [teamUsers]);
    

    const handleMemberChange = (value: string) => {
        setSelectedMember(value);
        getCardList(value)
    };

    const handleRowClick = (record: any) => {
        setSelectedCard(record);
        setModalVisible(true);
    };

    
    const columns = [
        {title: 'Kart Adı', dataIndex: 'cardName', key: 'cardName'},
        {
            title: 'Durum', dataIndex: 'periodName', key: 'periodName', render: (text: any, rec: any) => {
                return getStatusBadge(rec)
            }
        },
    ];

    const getStatusBadge = (record: any) => {
        const now = moment();

        if (moment(record.endDate).isBefore(now)) {
            return <Badge status="error" text={t('bitti')}/>;
        } else if (moment(record.startDate).isAfter(now)) {
            return <Badge status="warning" text={t('baslayacak')}/>;
        } else {
            return <Badge status="success" text={t('devam-ediyor')}/>;
        }
    };

    const getSelectedName = () => {
        var user = (teamUsers as UserDto[]).find((m) => m.id === selectedMember)
        return `${user?.firstName} ${user?.lastName}`
    }

    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <div>
            <Select
                placeholder="Ekip üyesi seçin"
                style={{width: 300, marginBottom: 16}}
                onChange={handleMemberChange}
                value={selectedMember || undefined} // Seçili eleman
                showSearch={true}
                filterOption={(input, option) =>
                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                }
                options={((teamUsers ?? []) as UserDto[]).map(k => {
                        return {value: k.id, label: `${k.firstName} ${k.lastName} ${k.title ? "(" + k.title + ")" : ""}`};
                    }
                )}
            />

            {selectedMember && (
                <Table
                    dataSource={performanceCardList}
                    columns={columns}
                    rowKey="id"
                    title={() => `Ekibimin OKR'ları (${getSelectedName()})`}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                    })}
                />
            )}

            <Modal
                title={selectedCard?.cardName ?? ""}
                visible={modalVisible}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
            >
                <TeamPerformanceCardModal card={selectedCard}/>
            </Modal>
        </div>
    );
};

export default TeamsOKRs;