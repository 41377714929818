import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiStrategicOkrGetStrategicOkRsByCompanyGroupId: build.query<
      GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiResponse,
      GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/GetStrategicOKRsByCompanyGroupId`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    getApiStrategicOkrGetStrategicOkRsBySystemAndPeriodId: build.query<
      GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiResponse,
      GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/GetStrategicOKRsBySystemAndPeriodId`,
        params: { systemId: queryArg.systemId, periodId: queryArg.periodId },
      }),
    }),
    postApiStrategicOkrSaveStrategicOkr: build.mutation<
      PostApiStrategicOkrSaveStrategicOkrApiResponse,
      PostApiStrategicOkrSaveStrategicOkrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/SaveStrategicOKR`,
        method: "POST",
        body: queryArg.saveStrategicOkrdto,
      }),
    }),
    putApiStrategicOkrUpdateStrategicOkrById: build.mutation<
      PutApiStrategicOkrUpdateStrategicOkrByIdApiResponse,
      PutApiStrategicOkrUpdateStrategicOkrByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/UpdateStrategicOKR/${queryArg.id}`,
        method: "PUT",
        body: queryArg.saveStrategicOkrdto,
      }),
    }),
    deleteApiStrategicOkrDeleteStrategicOkrById: build.mutation<
      DeleteApiStrategicOkrDeleteStrategicOkrByIdApiResponse,
      DeleteApiStrategicOkrDeleteStrategicOkrByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/DeleteStrategicOKR/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiResponse =
  /** status 200 OK */ StrategicOkrMasterDto[];
export type GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiArg = {
  companyGroupId?: string;
};
export type GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiResponse =
  /** status 200 OK */ StrategicOkrMasterDto[];
export type GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiArg = {
  systemId?: string;
  periodId?: string;
};
export type PostApiStrategicOkrSaveStrategicOkrApiResponse = unknown;
export type PostApiStrategicOkrSaveStrategicOkrApiArg = {
  saveStrategicOkrdto: SaveStrategicOkrdto;
};
export type PutApiStrategicOkrUpdateStrategicOkrByIdApiResponse = unknown;
export type PutApiStrategicOkrUpdateStrategicOkrByIdApiArg = {
  id: string;
  saveStrategicOkrdto: SaveStrategicOkrdto;
};
export type DeleteApiStrategicOkrDeleteStrategicOkrByIdApiResponse = unknown;
export type DeleteApiStrategicOkrDeleteStrategicOkrByIdApiArg = {
  id: string;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type OkrDetail = {
  id?: string;
  performanceSystemId?: string;
  okrShape?: Parameter;
  okrRelationship?: Parameter;
  okrType?: Parameter[] | null;
  okrWeight?: Parameter;
  okrPrivacy?: Parameter;
  okrTransparency?: Parameter;
  okrProgress?: Parameter;
  performanceSystem?: PerformanceSystem;
};
export type KpiDetail = {
  id?: string;
  performanceSystemId?: string;
  kpiRelationship?: Parameter;
  targetTypes?: Parameter[] | null;
  compentency?: Parameter;
  goalMeasurementType?: Parameter;
  goalTargetMeasurementSource?: Parameter[] | null;
  performanceSystem?: PerformanceSystem;
};
export type KpiGoalCompentencyDetails = {
  id?: string;
  performanceSystemId?: string;
  role?: Parameter;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
  performanceSystem?: PerformanceSystem;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRange = {
  id?: string;
  performanceSystemId?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
  performanceSystem?: PerformanceSystem;
};
export type PerformanceSystem = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetail[] | null;
  kpiDetails?: KpiDetail[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetails[] | null;
  performanceSystemRanges?: PerformanceSystemRange[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  companyGroupId?: string;
};
export type Period = {
  id?: string;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetail = {
  id?: string;
  strategicOKRMasterId?: string;
  strategicOKRMaster?: StrategicOkrMaster;
  strategicOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
};
export type StrategicOkrMaster = {
  id?: string;
  companyGroupId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  createdAt?: string;
  performanceSystem?: PerformanceSystem;
  performancePeriod?: Period;
  strategicOKRDetails?: StrategicOkrDetail[] | null;
};
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyGroupId?: string;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetailDto = {
  id?: string;
  strategicOKR?: string | null;
  strategicOKRId?: string;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
  period?: PeriodDto;
};
export type StrategicOkrMasterDto = {
  id?: string;
  companyGroupId?: string;
  performanceSystem?: PerformanceSystemDto;
  period?: PeriodDto;
  createdAt?: string;
  deletable?: boolean;
  okrDetails?: StrategicOkrDetailDto[] | null;
};
export type SaveStrategicOkrdto = {
  companyGroupId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  okrDetails?: StrategicOkrDetailDto[] | null;
};
export const {
  useGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery,
  useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery,
  useGetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdQuery,
  useLazyGetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdQuery,
  usePostApiStrategicOkrSaveStrategicOkrMutation,
  usePutApiStrategicOkrUpdateStrategicOkrByIdMutation,
  useDeleteApiStrategicOkrDeleteStrategicOkrByIdMutation,
} = injectedRtkApi;
