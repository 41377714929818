import React, {useEffect, useState} from 'react';
import {Table, Modal, Button, Badge} from 'antd';
import FeedbackPopup from '../../components/Feedback/FeedbackPopup';
import RequestFeedbackPopup from "../../components/Feedback/RequestFeedbackPopup";
import {
    useLazyGetApiFeedbackGetFeedbackRequestsByUserIdQuery,
    useLazyGetApiFeedbackGetReceivedFeedbacksByUserIdQuery, useLazyGetApiFeedbackGetRequestedFeedbacksByUserIdQuery
} from "../../api/services/Feedback";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import moment from "moment";

const ReceivedFeedbacks: React.FC = () => {
    const [feedbackData, setFeedbackData] = useState(null);
    const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
    const [isRequestFeedbackPopupVisible, setIsRequestFeedbackPopupVisible] = useState(false);
    const [fetchReceivedFeedbacks, {data: receivedFeedbacks}] = useLazyGetApiFeedbackGetReceivedFeedbacksByUserIdQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [fetchRequestedFeedbacks, {data: requestedFeedbacks}] = useLazyGetApiFeedbackGetRequestedFeedbacksByUserIdQuery()

    useEffect(() => {
        if (user) {
            getReceivedFeedbacks()
            getRequestedFeedbacks()
        }
    }, [user])

    const getReceivedFeedbacks = async () => {
        try {
            dispatch(setLoading(true));
            await fetchReceivedFeedbacks({
                userId: user?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Alınan geribildirimler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getRequestedFeedbacks = async () => {
        try {
            dispatch(setLoading(true));
            await fetchRequestedFeedbacks({
                userId: user?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Talep edilen geribildirimler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleRequestFeedback = () => {
        setIsRequestFeedbackPopupVisible(true);
    };

    const handleFeedbackClick = (record: any) => {
        setFeedbackData(record);
        setIsFeedbackPopupVisible(true);
    };

    const columns = [
        {title: 'Geri Bildirim Veren', dataIndex: 'fromUserName', key: 'fromUserName'},
        {title: 'Neden', dataIndex: 'feedbackReason', key: 'feedbackReason'},
        {
            title: 'Tarih', dataIndex: 'feedbackDate', key: 'feedbackDate',
            render: (_: any, record: any) => moment(record.feedbackDate).format('DD.MM.YYYY'),
        },
    ];

    const requestColumns = [
        {title: 'Talep Edilen Kişi', dataIndex: 'toUserName', key: 'toUserName'},
        {title: 'Talep Nedeni', dataIndex: 'feedbackReason', key: 'feedbackReason'},
        {
            title: 'Talep Tarihi', dataIndex: 'feedbackDate', key: 'feedbackDate',
            render: (_: any, record: any) => moment(record.feedbackDate).format('DD.MM.YYYY'),
        },
        {
            title: 'Durum', dataIndex: 'status', key: 'status',
            render: (_: any, record: any) => {
                if(record.status == 'Pending') {
                    return <Badge status="error" text={t('Cevap Bekliyor')} />
                }else if(record.status == 'Completed') {
                    return <Badge status="success" text={t('Tamamlandı')} />
                }
            }
        },
    ];
    
    return (
        <div>
            <div style={{marginBottom: 16, display: 'flex', justifyContent: 'space-between'}}>
                <Button type="primary" onClick={handleRequestFeedback}>
                    Geri Bildirim Talep Et
                </Button>
            </div>

            <Table
                dataSource={receivedFeedbacks}
                columns={columns}
                rowKey="id"
                title={() => 'Aldığım Geri Bildirimler'}
                style={{marginBottom: 32}}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => handleFeedbackClick(record),
                })}
            />

            <Table
                dataSource={requestedFeedbacks}
                columns={requestColumns}
                rowKey="id"
                title={() => 'Talep Ettiğim Geri Bildirimler'}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => handleFeedbackClick(record),
                })}
            />
            
            {/* Geri Bildirim Popup */}
            {feedbackData && (
                <FeedbackPopup
                    visible={isFeedbackPopupVisible}
                    data={feedbackData}
                    onClose={() => setIsFeedbackPopupVisible(false)}
                />
            )}

            {/* Geri Bildirim Talep Popup */}
            <RequestFeedbackPopup
                visible={isRequestFeedbackPopupVisible}
                onClose={() => setIsRequestFeedbackPopupVisible(false)}
                onChange={() => {
                    getReceivedFeedbacks()
                }}
            />
        </div>
    );
};

export default ReceivedFeedbacks;
