import React, {useEffect, useState} from 'react';
import {Table, Button, Modal, message, Badge} from 'antd';
import FeedbackPopup from '../../components/Feedback/FeedbackPopup';
import RequestFeedbackPopup from '../../components/Feedback/RequestFeedbackPopup';
import {useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery} from "../../api/services/DepartmentOKR";
import {
    FeedbackDto,
    useLazyGetApiFeedbackGetFeedbackRequestsByUserIdQuery,
    useLazyGetApiFeedbackGetGivenFeedbacksByUserIdQuery,
    useLazyGetApiFeedbackGetRequestedFeedbacksByUserIdQuery,
    usePostApiFeedbackAddFeedbackMutation
} from "../../api/services/Feedback";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import moment from "moment";
import GiveFeedbackPopup from "../../components/Feedback/GiveFeedbackPopup";
import AnswerFeedbackRequestPopup from "../../components/Feedback/AnswerFeedbackRequestPopup";
import CreateFeedbackPopup from "../../components/Feedback/CreateFeedbackPopup";

const MyFeedbacks: React.FC = () => {
    const [feedbackData, setFeedbackData] = useState(null);
    const [feedbackRequestData, setFeedbackRequestData] = useState<FeedbackDto | undefined>(undefined);
    const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
    const [isRequestFeedbackPopupVisible, setIsRequestFeedbackPopupVisible] = useState(false);
    const [isGiveFeedbackPopupVisible, setIsGiveFeedbackPopupVisible] = useState(false);
    const [fetchGivenFeedbacks, {data: givenFeedbacks}] = useLazyGetApiFeedbackGetGivenFeedbacksByUserIdQuery()
    
    const [fetchFeedbackRequests, {data: feedbackRequests}] = useLazyGetApiFeedbackGetFeedbackRequestsByUserIdQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()

    useEffect(() => {
        if (user) {
            refreshTables()
        }
    }, [user])

    const refreshTables = () => {
        getGivenFeedback()
        getFeedbackRequests()
    }
    const getGivenFeedback = async () => {
        try {
            dispatch(setLoading(true));
            await fetchGivenFeedbacks({
                userId: user?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Verilen geribildirimler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getFeedbackRequests = async () => {
        try {
            dispatch(setLoading(true));
            await fetchFeedbackRequests({
                userId: user?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Geribildirim talepleri çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleFeedbackClick = (record: any) => {
        setFeedbackData(record);
        setIsFeedbackPopupVisible(true);
    };

    const handleRequestClick = (record: any) => {
        setFeedbackRequestData(record);
        setIsRequestFeedbackPopupVisible(true);
    };

    const handleRequestFeedback = () => {
        setIsRequestFeedbackPopupVisible(true);
    };

    const handleGiveFeedback = () => {
        setIsGiveFeedbackPopupVisible(true);
    };
    
    const feedbackColumns = [
        {title: 'Alıcı', dataIndex: 'toUserName', key: 'toUserName'},
        {title: 'Neden', dataIndex: 'feedbackReason', key: 'feedbackReason'},
        {
            title: 'Tarih', dataIndex: 'feedbackDate', key: 'feedbackDate',
            render: (_: any, record: any) => moment(record.feedbackDate).format('DD.MM.YYYY'),
        },
    ];

    const requestColumns = [
        {title: 'Talep Eden', dataIndex: 'fromUserName', key: 'fromUserName'},
        {title: 'Neden', dataIndex: 'feedbackReason', key: 'feedbackReason'},
        {
            title: 'Tarih', dataIndex: 'feedbackDate', key: 'feedbackDate',
            render: (_: any, record: any) => moment(record.feedbackDate).format('DD.MM.YYYY'),
        },
        {
            title: 'Durum', dataIndex: 'status', key: 'status',
            render: (_: any, record: any) => {
                if(record.status == 'Pending') {
                    return <Badge status="error" text={t('Cevap Bekliyor')} />
                }else if(record.status == 'Completed') {
                    return <Badge status="success" text={t('Tamamlandı')} />
                }
            }
        },
    ];

    return (
        <div>
            <Button type="primary" onClick={handleGiveFeedback} style={{marginBottom: 16}}>
                Geri Bildirim Ver
            </Button>

            <Table
                dataSource={feedbackRequests}
                columns={requestColumns}
                rowKey="id"
                title={() => 'Benden Talep Edilen Geri Bildirimler'}
                pagination={false}
                style={{marginBottom: 32}}
                onRow={(record) => ({
                    onClick: () => handleRequestClick(record),
                })}
            />
            
            <Table
                dataSource={givenFeedbacks}
                columns={feedbackColumns}
                rowKey="id"
                title={() => 'Verdiğim Geri Bildirimler'}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => handleFeedbackClick(record),
                })}
            />

           
            
            {/* Geri Bildirim Popup */}
            {feedbackData && (
                <FeedbackPopup
                    visible={isFeedbackPopupVisible}
                    data={feedbackData}
                    onClose={() => {
                        setIsFeedbackPopupVisible(false);
                    }}
                />
            )}

            {/* Geri Bildirim Talep Popup */}
            <CreateFeedbackPopup
                visible={isGiveFeedbackPopupVisible}
                onClose={() => {
                    setIsGiveFeedbackPopupVisible(false);
                }}
                onUpdate={() => {
                    refreshTables();
                }}
                request={feedbackRequestData}
            />

            {/* Geri Bildirim Talep Popup */}
            <AnswerFeedbackRequestPopup
                visible={isRequestFeedbackPopupVisible}
                onClose={() => {
                    setIsRequestFeedbackPopupVisible(false);
                }}
                onUpdate={() => {
                    refreshTables();
                }}
                request={feedbackRequestData}
            />
        </div>
    );
};

export default MyFeedbacks;
