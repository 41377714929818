import React from 'react';
import {Card, Col, Divider, Row, Statistic} from "antd";
import {PerformanceResultTypes} from "./PerformanceResultType";
import {IDetailItem} from "../data";

export interface IPerformanceResultsTabProps {
    selectedItem: IDetailItem | undefined
}

const PerformanceResultsTabProps: React.FC<IPerformanceResultsTabProps> = ({selectedItem}) => {
    return (<>

        <div>
            <div>
                <Divider orientation="left">Performans Boyutu</Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Card bordered={false}>
                            <Statistic
                                title="İş Sonuçlarım (What)"
                                value={PerformanceResultTypes.GetType(selectedItem?.performanceResults.what ?? 0)?.name}
                                valueStyle={{color: '#3f8600'}}
                                prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.what ?? 0)?.icon}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false}>
                            <Statistic
                                title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                value={PerformanceResultTypes.GetType(selectedItem?.performanceResults.how ?? 0)?.name}
                                valueStyle={{color: '#3f8600'}}
                                prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.how ?? 0)?.icon}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className={"mt-[30px]"}>
                <Divider orientation="left">Kalibre Edilmiş Performans Boyutu</Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Card bordered={false}>
                            <Statistic
                                title="İş Sonuçlarım (What)"
                                value={PerformanceResultTypes.GetType(selectedItem?.performanceResults.calibratedWhat ?? 0)?.name}
                                valueStyle={{color: '#3f8600'}}
                                prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.calibratedWhat ?? 0)?.icon}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false}>
                            <Statistic
                                title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                value={PerformanceResultTypes.GetType(selectedItem?.performanceResults.calibratedHow ?? 0)?.name}
                                valueStyle={{color: '#3f8600'}}
                                prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.calibratedHow ?? 0)?.icon}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>

        </div>
    </>)
};

export default PerformanceResultsTabProps;