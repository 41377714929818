import {Form, Select} from "antd";
import {CSSProperties} from "react";
import {DataItem} from "../models/DataItem";
import type {Rule} from "rc-field-form/lib/interface";

interface FormItemComboboxProps {
    name: string,
    label: string,
    datas: DataItem[],
    onChange?: (item: DataItem) => void,
    multiSelectEnabled?: boolean
    style?: CSSProperties
    placeholder?: string
    disabled?: boolean
    rules?: Rule[];
}

const FormItemCombobox: React.FC<FormItemComboboxProps> = ({
                                                               name,
                                                               label,
                                                               datas,
                                                               onChange,
                                                               disabled,
                                                               rules,
                                                               placeholder = "",
                                                               multiSelectEnabled = false,
                                                               style = {width: "50%"}
                                                           }) => {
    return (<>
        <Form.Item name={name} label={label} style={style} rules={rules}>
            <Select onChange={(e) => onChange ? onChange(datas.find(k => k.id === e)!) : {}}
                    mode={multiSelectEnabled ? "multiple" : undefined}
                    disabled={disabled ?? false}
                    placeholder={placeholder}>
                {datas.map(data => (
                    <Select.Option key={data.id} value={data.id}>
                        {data.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    </>);
}

export default FormItemCombobox;