import React from 'react';
import {Badge, Modal, Typography} from 'antd';
import moment from "moment";
import {t} from "i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";

const {Title, Paragraph} = Typography;

interface FeedbackPopupProps {
    visible: boolean;
    data: any;
    onClose: () => void;
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({visible, data, onClose}) => {
    const user = useSelector((state: RootState) => state.auth.user);
    
    return (
        <Modal visible={visible}
               title={data.type == "Requested" ? "Geri Bildirim Talep Detayı" : "Geri Bildirim Detayı"} footer={null}
               onCancel={onClose}>
            {data.type === "Requested" &&
                <div className={"mb-3"}>
                    <Title level={5}>Durum</Title>
                    {data?.status == 'Pending' &&
                        <Badge status="error" text={t('Cevap Bekliyor')}/>
                    }
                    {data?.status == 'Completed' &&
                        <Badge status="success" text={t('Tamamlandı')}/>
                    }
                </div>
            }
            {data.type !== "Requested" &&
                <>
                    <Title level={5}>Geribildirim Tarihi</Title>
                    <Paragraph>{moment(data.feedbackDate).format("DD.MM.YYYY")}</Paragraph>
                </>
            }
            {data.type === "Requested" &&
                <>
                    <Title level={5}>Geribildirim Talep Tarihi</Title>
                    <Paragraph>{moment(data.feedbackDate).format("DD.MM.YYYY")}</Paragraph>
                </>
            }
            {data.type === "Requested" && data.responseDate &&
                <>
                    <Title level={5}>Geribildirim Cevap Tarihi</Title>
                    <Paragraph>{moment(data.responseDate).format("DD.MM.YYYY")}</Paragraph>
                </>
            }
            {data.fromUserId !== user?.id &&
                <>
                    <Title level={5}>{"Gönderen"}</Title>
                    <Paragraph>{data.fromUserName}</Paragraph>
                </>
            }
            
            {data.toUserName !== user?.id &&
                <>
                    <Title level={5}>{data.type === "Requested" ? "Talep Edilen" : "Alan"}</Title>
                    <Paragraph>{data.toUserName}</Paragraph>
                </>
            }
            
            <Title level={5}>Geri Bildirim Nedeni</Title>
            <Paragraph>{data.feedbackReason}</Paragraph>
            {
                data.type === "Requested" &&
                <>
                    <Title level={5}>Talep Detayı</Title>
                    <Paragraph>{data.requestDetail}</Paragraph>
                </>
            }

            {
                data.type === "Requested" && data.responseDetail &&
                <>
                    <Title level={5}>Talep Cevabı Detayı</Title>
                    <Paragraph>{data.responseDetail}</Paragraph>
                </>
            }

            {
                data.type !== "Requested" &&
                <>
                    <Title level={5}>Detay</Title>
                    <Paragraph>{data.detail}</Paragraph>
                </>
            }
        </Modal>
    )
        ;
};

export default FeedbackPopup;
