import React, {useEffect, useState} from 'react';
import {Table, Button, Input, Select, Form, Modal} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import DepartmentOKRGoalTableRow from './DepartmentOKRGoalTableRow';
import DepartmentOKRGoalTableSummary from './DepartmentOKRGoalTableSummary';
import TextArea from 'antd/es/input/TextArea';
import {kpiGoalCalculationSource} from '../../data/data';
import {DataItem} from '../../models/DataItem';
import {PerformanceGoalDetailDto, PerformanceGoalDto} from '../../api/services/PerformanceGoal';
import {StrategicOkrMasterDto} from '../../api/services/company';
import {StrategicOkrDetailDto} from '../../api/services/StrategicOKR';
import moment from "moment/moment";
import {WeightenedOKRId} from "../../data/constants";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {t} from "i18next";
import {Guid} from "typescript-guid";

const initialRows: PerformanceGoalDetailDto[] = [
    {strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0},
];

const {confirm} = Modal;

export interface DepartmentOKRGoalTableParams {
    goal: any | undefined
    selectedOKR: StrategicOkrMasterDto | undefined
    deleteRow: (id: string) => void
}

const DepartmentOKRGoalTable: React.FC<DepartmentOKRGoalTableParams> = ({
                                                                            goal,
                                                                            selectedOKR,
                                                                            deleteRow
                                                                        }) => {
    const [dataSource, setDataSource] = useState<any[]>(goal?.okrDetails ?? initialRows);
    const [strategicOKRDetails, setStrategicOKRDetails] = useState<StrategicOkrDetailDto[]>([])
    const [selectedPerformanceSystem, setSelectedPerformanceSystem] = useState<string | undefined>()
    
    useEffect(() => {
        if (selectedOKR && selectedOKR.id != null) {
            setStrategicOKRDetails(selectedOKR.okrDetails ?? [])
            setSelectedPerformanceSystem(selectedOKR.performanceSystem?.okrDetails?.[0].okrWeightId)
        }
    }, [selectedOKR])

    useEffect(() => {
        if (goal?.okrDetails) {
            console.log(goal?.okrDetails)
            setDataSource(goal?.okrDetails)
        }
    }, [goal?.okrDetails])


    const handleAddRow = () => {
        const newRow: PerformanceGoalDetailDto = {
            id: `${dataSource.length + 1}`,
            strategicGoal: '',
            kpi: '',
            weight: 0,
            source: '',
            minResult: 0,
            targetResult: 0,
            maxResult: 0,
        };
        setDataSource([...dataSource, newRow]);
    };

    const handleChange = (key: string, field: string, value: any) => {
        const newData = dataSource.map((row) => {
            if (row.id === key) {
                return {...row, [field]: value};
            }
            return row;
        });
        setDataSource(newData);
    };
    
    const columns = [
        {
            title: 'Stratejik OKR',
            dataIndex: 'okr',
            key: 'okr',
            width: 400,
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    className='mb-0'
                    name={["rows", index, "okr"]}
                    rules={[
                        {required: true, message: "Bir Stratejik OKR Seçmelisiniz"}
                    ]}
                    initialValue={text}
                    style={{width: 400}}
                >
                    <Select
                        value={text}
                        onChange={(value) => handleChange(record.id ?? "", 'source', value)}
                        style={{height: '40px', width: "400px"}}
                        showSearch={true}
                        filterOption={(input, option) =>
                            `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                        }
                        options={strategicOKRDetails
                            .map(k => {
                                    return {
                                        value: k.id,
                                        label: `${k.strategicOKR}`
                                    };
                                }
                            )}
                    />

                </Form.Item>

            ),
        },
        {
            title: 'Departman OKR\'ı',
            dataIndex: 'departmentOkr',
            key: 'departmentOkr',
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    className='mb-0'
                    name={["rows", index, "departmentOkr"]}
                     rules={[
                       { required: true, message: "Departman OKR\'ı yazmalısınız" }
                     ]}
                    initialValue={text}
                >
                    <TextArea
                        value={text}
                        onChange={(e) => handleChange(record.id ?? "", 'departmentOkr', e.target.value)}
                        style={{height: '40px', resize: 'none'}} // TextArea için yükseklik ayarı
                    />
                </Form.Item>

            ),
        },
        {
            hidden: selectedPerformanceSystem != WeightenedOKRId,
            title: 'OKR Ağırlık %',
            dataIndex: 'weight',
            key: 'weight',
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    className='mb-0'
                    name={["rows", index, "weight"]}
                     rules={[
                       { required: selectedPerformanceSystem == WeightenedOKRId, message: "OKR Ağırlık yazmalısınız" }
                     ]}
                    initialValue={text}
                >
                    <Input
                        type="number"
                        min={0}
                        max={100}
                        value={text}
                        onChange={(e) => handleChange(record.id ?? "", 'weight', parseFloat(e.target.value))}
                        suffix="%"
                        style={{height: '40px'}} // Input için yükseklik ayarı
                    />
                </Form.Item>

            ),
        },
        {
            hidden: selectedPerformanceSystem != WeightenedOKRId,
            title: 'Hedef Ölçüm Kaynağı',
            dataIndex: 'goalMeasurementSource',
            key: 'goalMeasurementSource',
            render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    name={["rows", index, "goalMeasurementSource"]}
                    className='mb-0'
                     rules={[
                       { required: selectedPerformanceSystem == WeightenedOKRId, message: "Hedef Ölçüm Kaynağı seçmelisiniz" }
                     ]}
                    initialValue={text}
                >
                    <Select
                        value={text}
                        onChange={(value) => handleChange(record.id ?? "", 'source', value)}
                        style={{height: '40px'}} // Select için yükseklik ayarı
                    >
                        {kpiGoalCalculationSource.map((item: DataItem) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

            ),
        },
        {
            hidden: selectedPerformanceSystem != WeightenedOKRId,
            title: 'Hedef Sonuç',
            dataIndex: 'source',
            key: 'source',
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    name={["rows", index, "source"]}
                    className='mb-0'
                     rules={[
                       { required: selectedPerformanceSystem == WeightenedOKRId, message: "Hedef Sonuç yazmalısınız" }
                     ]}
                    initialValue={text}
                >

                    <Input
                        type="text"
                        value={text}
                        onChange={(e) => handleChange(record.id ?? "", 'source', parseFloat(e.target.value))}
                        style={{height: '40px'}} // Input için yükseklik ayarı
                    />
                </Form.Item>

            ),
        },
        {
            title: '', dataIndex: 'isDeletable', key: 'isDeletable',

            width: 70,
            render: (data: any, rec: any) => (

                <Button
                    disabled={rec.isDeletable == false}
                    onClick={(e) => {
                        e.stopPropagation()
                        showDeleteConfirm(rec.id)

                    }}>
                    <DeleteOutlined/>
                </Button>
            )
        },
    ];

    const showDeleteConfirm = (id: string) => {
        confirm({
            title: "Bu kaydı silmek istediğinize emin misiniz?",
            icon: <ExclamationCircleOutlined/>,
            content: "Silindikten sonra geri alamazsınız.",
            okText: t('yes'),
            okType: 'danger',
            cancelText: t('no'),
            onOk() {
                handleDelete(id);
            },
        });
    };

    const handleDelete = async (id: string) => {
        
        var rows = dataSource.filter(k => k.id !== id)
        setDataSource([...rows]);
        if(!Guid.isGuid(id)) return
        await deleteRow(id);
    }
    
    return (
        <>
            <Button onClick={handleAddRow} type="primary" icon={<PlusOutlined/>} style={{marginBottom: 16}}>
                Satır Ekle
            </Button>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
                rowKey="key"
            />
        </>
    );
};

export default DepartmentOKRGoalTable;
